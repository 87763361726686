@tailwind base;
@tailwind components;
@tailwind utilities;

/* Gradients */
.gradient-1 {
  background: linear-gradient(90deg, #e9e231 0%, #d8274d 100%);
}
.gradient-2 {
  background: linear-gradient(45deg, #398595 0%, #00816c 100%);
}
.gradient-3 {
  background: linear-gradient(80deg, #b00a5e 60%, #398595 100%);
}
.gradient-4 {
  background: linear-gradient(45deg, #d87a1f 40%, #f1c741 100%);
}
.gradient-5 {
  background: linear-gradient(180deg, #398595 0%, #d87a1f 40%);
}
.gradient-6 {
  background: linear-gradient(45deg, #d87a1f 15%, #398595 60%);
}

/* Full Calendar */
:root {
  --fc-button-bg-color: #b00a5e;
  --fc-button-border-color: #b00a5e;
  --fc-button-active-bg-color: #398595;
  --fc-button-active-border-color: #398595;
  --fc-button-hover-bg-color: #398595;
  --fc-button-hover-border-color: #398595;
  --fc-event-bg-color: #b00a5e;
  --fc-event-border-color: #b00a5e;
}

.fc .fc-button:focus,
.fc .fc-button-active:focus {
  @apply shadow-none ring-2 ring-primary !important;
}

.fc-event-main {
  overflow: hidden;
}

/* Expand the calendar within flexbox layouts */
.fc-media-screen {
  flex-grow: 1;
}

/* Event that are being created have no border */
.fc-v-event {
  border: none !important;
}

.text-nowrap {
  text-wrap: nowrap;
}
